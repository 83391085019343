import { completeAssign } from 'js/utils/complete-assign'
import { merge } from 'js/utils/merge'
import { bindEvent, v } from 'js/utils/v'
import { normalizeStr } from 'js/utils/normalize-str'
import { inputFieldView } from 'components/base/input-field/input-field.view'
import { buttonView } from 'components/base/button/button.view'

// Defaults for view model
const defaults = {}

/**
 * @function template
 *
 * @desc Renders template with given view model.
 *
 * @param {Object} data - View model
 * @returns {string} HTML string of component
 */
const template = ({ pgmName }) => `
<div class="program-manager">
  <form class="program-manager__form" data-ref="form">
      <div class="program-manager__pgm-name">
          ${inputFieldView.tpl({
    modifiers: 'mono label-left',
    value: '',
    name: 'pgmName',
    maxlength: 16,
    required: true,
    ref: 'pgmName'
  })} 
      </div>
      
      <div class="program-manager__actions">       
          ${buttonView.tpl({
    label: 'Save',
    ref: 'save'
  })}
          
          ${buttonView.tpl({
  label: 'Load',
  type: 'button',
  ref: 'load'
})}
                  
          ${buttonView.tpl({
    label: 'Reset',
    type: 'button',
    ref: 'reset'
  })}
      </div>
  </form>
</div>
`

/**
 * View factory
 *
 * @param {string|Element} el - Selector or DOM element. This element will be replaced by the rendered component.
 * @param {Object} viewModel - View model
 * @param {Element|*} [root=document] - This is only used when `el` is a selector.
 *
 * @returns {Object} View instance
 */
const view = (el, viewModel = {}, root = document) => {
  const vm = merge({}, defaults, viewModel)
  const instance = completeAssign({}, v({
    tpl: view.tpl,
    data: vm,
    mount: el,
    root
  }))

  // Private vars

  // Private methods
  const init = () => {
    bind()
  }

  const bind = () => {
    bindEvent('click', instance.refs.load, () => {
      instance.trigger('load')
    })

    bindEvent('click', instance.refs.reset, () => {
      instance.trigger('reset')
    })

    bindEvent('change', instance.refs.pgmName, () => {
      instance.refs.pgmName.value = normalizeStr(instance.refs.pgmName.value)
    })

    bindEvent('submit', instance.refs.form, (e) => {
      e.preventDefault()

      instance.trigger('save', {
        pgmName: instance.refs.pgmName.value
      })
    })
  }

  // Public vars

  // Public methods
  instance.update = (pgmName) => {
    instance.refs.pgmName.value = pgmName
  }

  instance.init = () => {
    instance.refs.pgmName.disabled = false
    instance.refs.save.disabled = false
    instance.refs.load.disabled = false
    instance.refs.reset.disabled = false
  }

  instance.reset = () => {
    instance.refs.pgmName.disabled = true
    instance.refs.save.disabled = true
    instance.refs.load.disabled = true
    instance.refs.reset.disabled = true
  }

  // Hooks
  instance.on('rendered', init)

  return instance
}

// Static methods
view.tpl = template

export {
  view as programManagerView
}
