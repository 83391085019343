/**
 * Normalize to following criterias
 * - max. 16 characters (16 bytes, ASCII)
 * - Whitespaces: Only spaces
 * - Special characters: !#$%&'()-@_{}
 * - Alphanumeric characters
 *
 * @param {string} str
 */
export const normalizeStr = (str) => {
  str = str.substring(0, 16)
  str = str.replace(/\s/g, ' ')
  str = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  str = str.replace(/[^A-z0-9!#$%&'()\-@_{}\s]/g, '_')

  return str
}
