import { completeAssign } from 'js/utils/complete-assign'
import { merge } from 'js/utils/merge'
import { bindEvent, v } from 'js/utils/v'
import { sampleEditSelectView } from 'components/sample-edit-select/sample-edit-select.view'
import { sampleEditController } from 'components/sample-edit/sample-edit.controller'
import { sampleEditView } from 'components/sample-edit/sample-edit.view'
import { selectFieldView } from 'components/base/select-field/select-field.view'
import { voiceOverlapDropdown, muteGroupDropdown, midiInDropdown } from 'js/modules/program'
import { inputFieldView } from '../base/input-field/input-field.view'
import { fixTypes } from 'js/utils/fix-types'

// Defaults for view model
const defaults = {}

/**
 * @function template
 *
 * @desc Renders template with given view model.
 *
 * @returns {string} HTML string of component
 */
const template = () => `
<div class="pad-edit">
    <div class="pad-edit__no-selection tx-title">
        Select a pad to edit
    </div>
    
    <div class="pad-edit__main">
        <h1 class="pad-edit__title tx-title" data-ref="title"></h1>
        
        <div class="pad-edit__sample-edit-select">
            ${sampleEditSelectView.tpl()}
        </div>
        
        <div class="pad-edit__sample-detail" data-sample-nr="0" data-ref="sampleEdit">
        </div>
        
        <div class="pad-edit__sample-detail" data-sample-nr="1" data-ref="sampleEdit">
        </div>
        
        <div class="pad-edit__sample-detail" data-sample-nr="2" data-ref="sampleEdit">
        </div>
        
        <div class="pad-edit__sample-detail" data-sample-nr="3" data-ref="sampleEdit">
        </div>
       
        <!-- Pad Params --> 
        <form class="pad-edit__pad-params" data-ref="padParams">
            <div class="form-row">
                <div class="form-col half">
                    ${selectFieldView.tpl({
  label: 'Voice Overlap',
  options: voiceOverlapDropdown,
  name: 'voiceOverlap',
  ref: 'voiceOverlap'
})}
                </div>
                  
                <div class="form-col half">
                    ${selectFieldView.tpl({
  label: 'Mute Group',
  options: muteGroupDropdown,
  name: 'muteGroup',
  ref: 'muteGroup'
})}
                </div>
            </div>
            
            <div class="form-row">
                <div class="form-col half">
                    ${inputFieldView.tpl({
  label: 'Velocity to level',
  name: 'velocityToLevel',
  type: 'number',
  min: '0',
  max: '100',
  ref: 'velocityToLevel'
})}
                </div>
            </div>
            
            <div class="form-row">
                <div class="form-col half">
                    ${inputFieldView.tpl({
  label: 'mixer level',
  name: 'mixerLevel',
  type: 'number',
  min: '0',
  max: '100',
  ref: 'mixerLevel'
})}
                </div>
                <div class="form-col half">
                    ${inputFieldView.tpl({
  label: 'mixer pan',
  name: 'mixerPan',
  type: 'number',
  min: '0',
  max: '100',
  ref: 'mixerPan'
})}
                </div>
            </div>
        </form>
        
        <!-- MIDI params -->
        <form class="pad-edit__midi-params" data-ref="midiParams">
            <div class="form-row">
                <div class="form-col half">
                    ${selectFieldView.tpl({
  label: 'MIDI IN',
  name: 'padToMidi',
  options: midiInDropdown,
  ref: 'padToMidi'
})}
                </div>
            </div>
        </form>
    </div>
</div>
`

/**
 * View factory
 *
 * @param {string|Element} el - Selector or DOM element. This element will be replaced by the rendered component.
 * @param {Object} viewModel - View model
 * @param {Element|*} [root=document] - This is only used when `el` is a selector.
 *
 * @returns {Object} View instance
 */
const view = (el, viewModel = {}, root = document) => {
  const vm = merge({}, defaults, viewModel)
  const instance = completeAssign({}, v({
    tpl: view.tpl,
    data: vm,
    mount: el,
    root
  }))

  // Private vars
  const sampleEditViews = []

  // Private methods
  const init = () => {
    instance.refs.sampleEdit.forEach((se, idx) => {
      const seView = sampleEditView(se, { sampleNr: idx })
      sampleEditViews.push(seView)
      sampleEditController({
        view: seView
      })
    })

    bind()
  }

  const onSampleEditSelect = (e) => {
    const selected = Number(e.target.value)

    instance.refs.sampleEdit.forEach((se, idx) => {
      instance.setState(selected === idx ? 'selected' : '', se)
    })
  }

  const savePadParams = () => {
    const padParams = Object.fromEntries(new window.FormData(instance.refs.padParams))
    fixTypes(padParams)

    instance.trigger('save-pad-params', {
      padParams
    })
  }

  const saveMidiParams = () => {
    const midiParams = Object.fromEntries(new window.FormData(instance.refs.midiParams))
    fixTypes(midiParams)

    instance.trigger('save-midi-params', {
      midiParams
    })
  }

  const bind = () => {
    bindEvent('change', instance.refs.sampleEditSelect, onSampleEditSelect)
    bindEvent('change', instance.refs.padParams, savePadParams)
    bindEvent('submit', instance.refs.padParams, (e) => {
      e.preventDefault()
    })
    bindEvent('change', instance.refs.midiParams, saveMidiParams)
    bindEvent('submit', instance.refs.midiParams, (e) => {
      e.preventDefault()
    })
  }

  // Public vars

  // Public methods
  instance.reset = () => {
    instance.setState('')
  }

  instance.update = (padConfig) => {
    instance.refs.title.innerHTML = padConfig.title

    // Update sample edit fields
    for (let s = 4; s--;) {
      sampleEditViews[s].update(padConfig.samples[s])
    }

    // Update pad param fields
    instance.refs.voiceOverlap.value = padConfig.voiceOverlap
    instance.refs.muteGroup.value = padConfig.muteGroup
    instance.refs.velocityToLevel.value = padConfig.velocityToLevel
    instance.refs.mixerLevel.value = padConfig.mixerLevel
    instance.refs.mixerPan.value = padConfig.mixerPan

    // Update midi fields
    instance.refs.padToMidi.value = padConfig.midiNote
  }

  instance.resetSampleSelect = () => {
    // Select sample1
    instance.refs.sampleEditSelect[0].click()

    instance.setState('pad-selected')
  }

  // Hooks
  instance.on('rendered', init)

  return instance
}

// Static methods
view.tpl = template

export {
  view as padEditView
}
