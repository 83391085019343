import { midi } from 'js/modules/midi'
import 'js/components-mapping'
import { appFrameView } from 'components/app-frame/app-frame.view'
import { programManagerController } from 'components/program-manager/program-manager.controller'
import { programManagerView } from 'components/program-manager/program-manager.view'
import { samplerController } from 'components/sampler/sampler.controller'
import { samplerFactory } from 'components/sampler/sampler'
import { programManagerFactory } from 'components/program-manager/program-manager'
import { actions } from 'js/modules/actions'
import { libraryFactory } from 'components/library/library'
import { libraryController } from 'components/library/library.controller'
import { libraryView } from 'components/library/library.view'
import { bankSelectController } from 'components/bank-select/bank-select.controller'
import { bankSelectView } from 'components/bank-select/bank-select.view'
import { pubsub } from 'js/modules/pubsub'
import { padsController } from 'components/pads/pads.controller'
import { padsView } from 'components/pads/pads.view'
import { createProgramSkeleton } from 'js/modules/program'
import { padEditController } from 'components/pad-edit/pad-edit.controller'
import { padEditView } from 'components/pad-edit/pad-edit.view'
import { padEditFactory } from 'components/pad-edit/pad-edit'
import { midiMonitorController } from 'components/midi-monitor/midi-monitor.controller'
import { midiMonitorView } from '../components/midi-monitor/midi-monitor.view'

// Create audiocontext
window.audiocontext = window.audiocontext || new window.AudioContext()

midi.init()
// initStore()

// Render app frame
document.getElementById('app').innerHTML = appFrameView.tpl(document.getElementById('app'))

// 'Globals'
const programManager = programManagerFactory()
const library = libraryFactory()

/** **** Global actions *******/
actions.getProgramConfig = function (pgmManager) {
  return pgmManager.getProgram()
}.bind(null, programManager)

actions.getSamplesDropdown = function (lib) {
  return lib.getSamplesDropdownList()
}.bind(null, library)

actions.getReferencedSamples = function (lib) {
  return lib.getReferencedSamples(actions.getProgramConfig())
}.bind(null, library)

actions.getSampleId = function (lib, sampleName) {
  return lib.getSampleId(sampleName)
}.bind(null, library)

actions.getSample = function (lib, sampleId) {
  return lib.getSample(sampleId)
}.bind(null, library)

actions.getSampleName = function (lib, sampleId) {
  return lib.getSampleNameById(sampleId)
}.bind(null, library)

actions.loadFilesToLibrary = async function (library, files) {
  await library.loadSamples(files)
}.bind(null, library)

actions.load = function (pgmFileName, pgmConfig, sampleFiles) {
  pubsub.trigger('app/reset')

  programManager.load(pgmFileName, pgmConfig, sampleFiles).then(() => {
    // Now start all modules
    pubsub.trigger('app/init')
  })
}

actions.reset = function () {
  actions.load('NEW_PROGRAM', createProgramSkeleton(), [])
}

/** ***** Instantiate all modules *********/
programManagerController({
  view: programManagerView(document.querySelector('[data-ref="programManager"]')),
  model: programManager
})

samplerController({
  model: samplerFactory()
})

libraryController({
  view: libraryView(document.querySelector('[data-ref="sampleLibrary"]')),
  model: library
})

bankSelectController({
  view: bankSelectView(document.querySelector('[data-ref="bankSelect"]'))
})

padsController({
  view: padsView(document.querySelector('[data-ref="pads"]'))
})

padEditController({
  view: padEditView(document.querySelector('[data-ref="padEditContainer"]')),
  model: padEditFactory()
})

midiMonitorController({
  view: midiMonitorView(document.querySelector('[data-ref="midiMonitor"]'))
})

// Initial state
actions.reset()
