const bytesFromUInt32 = (number) => {
  const bytesArray = []
  let arrayLength = 4

  do {
    bytesArray[--arrayLength] = number & 0xff
    number = number >> 8
  } while (arrayLength)

  return bytesArray
}

/**
 * Check if given buffer is a WAV file
 *
 * @param {ArrayBuffer} arrayBuffer
 * @returns {boolean}
 */
const isWav = (arrayBuffer) => {
  const view = new DataView(arrayBuffer)

  // Reference http://soundfile.sapp.org/doc/WaveFormat/
  const chunkId = String.fromCharCode(...bytesFromUInt32(view.getUint32(0)))
  const format = String.fromCharCode(...bytesFromUInt32(view.getUint32(8)))

  return chunkId === 'RIFF' && format === 'WAVE'
}

export {
  isWav
}
