import { completeAssign } from 'js/utils/complete-assign'
import { merge } from 'js/utils/merge'
import { bindEvent, v } from 'js/utils/v'
import { buttonView } from 'components/base/button/button.view'
import { sampleRowView } from '../sample-row/sample-row.view'
import { round } from 'js/utils/round'

// Defaults for view model
const defaults = {
  sortProp: 'filename', // TODO: implement sorting logic
  sortDir: 'asc' // TODO: implement sorting logic
}

const getSize = (bytes) => {
  let size = bytes / Math.pow(1024, 1)
  let val = ' kB'

  if (size > 1024) {
    size = bytes / Math.pow(1024, 2)
    val = ' MB'
  }

  if (size > 1024) {
    size = bytes / Math.pow(1024, 3)
    val = ' GB'
  }

  return round(size) + val
}

const formatDuration = (duration) => {
  let val = ''

  if (duration < 10 ** 3) {
    val = duration + ' ms'
  }

  if (duration >= 10 ** 3) {
    val = round(duration / 10 ** 3, 1) + ' s'
  }

  return val
}

/**
 * @function template
 *
 * @desc Renders template with given view model.
 *
 * @param {Object} data - View model
 * @returns {string} HTML string of component
 */
const template = (data) => `
<div class="library">
    <div class="library__buttons">
        <div class="library__button">
            ${buttonView.tpl({ label: 'load', ref: 'loadSamplesTrigger' })}
        </div>
                
        <div class="library__button">
            ${buttonView.tpl({ label: 'purge', ref: 'purgeSamples' })}
        </div>
        
        <input type="file" 
                class="visuallyhidden" 
                data-ref="loadSamples" 
                multiple 
                accept="audio/wav,audio/wave">
    </div>

    <div class="libray__table-container">
      <table class="library__table">
          <thead>
              <tr>
                  <th><div class="tx-label-small">Samplename</div></th>
                  <th><div class="tx-label-small">Size</div></th>
                  <th><div class="tx-label-small">Duration</div></th>
                  <th>&nbsp;</th>
              </tr>
          </thead>
          <tbody data-ref="list"></tbody>
      </table>
    </div>
</div>
`

/**
 * View factory
 *
 * @param {string|Element} el - Selector or DOM element. This element will be replaced by the rendered component.
 * @param {Object} viewModel - View model
 * @param {Element|*} [root=document] - This is only used when `el` is a selector.
 *
 * @returns {Object} View instance
 */
const view = (el, viewModel = {}, root = document) => {
  const vm = merge({}, defaults, viewModel)
  const instance = completeAssign({}, v({
    tpl: view.tpl,
    data: vm,
    mount: el,
    root
  }))

  // Private vars

  // Private methods
  const init = () => {
    bind()
  }

  const onLoadClick = () => {
    instance.refs.loadSamples.click()
  }

  const onPurgeSamplesClick = () => {
    instance.trigger('purge')
  }

  const onLoadSamples = (e) => {
    instance.trigger('load', {
      files: e.target.files
    })
  }

  const onListItemClick = (e) => {
    if (e.target.closest('[data-ref="playSample"]')) {
      const btn = e.target.closest('[data-ref="playSample"]')
      instance.trigger('play-sample', {
        sampleId: Number(btn.getAttribute('data-sample-id'))
      })

      return
    }

    if (e.target.closest('[data-ref="deleteSample"]')) {
      const btn = e.target.closest('[data-ref="deleteSample"]')
      instance.trigger('delete-sample', {
        sampleId: Number(btn.getAttribute('data-sample-id'))
      })
    }
  }

  const activateInput = (input) => {
    if (input.readOnly === false) {
      return
    }

    const oldValue = input.value

    const restoreInput = () => {
      input.readOnly = true
      input.closest('form').removeEventListener('submit', onSubmit)
      input.removeEventListener('keyup', onKeyup)
    }

    const onSubmit = (e) => {
      e.preventDefault()

      restoreInput()
      instance.trigger('edit-sample', {
        sampleId: Number(input.getAttribute('data-sample-id')),
        samplename: input.value
      })
    }

    const onKeyup = (e) => {
      if (e.code === 'Escape') {
        // Don't save changes.
        input.value = oldValue
        restoreInput()
      }
    }

    // TODO: Don't allow deleting samples. Only one sample-name should edited at a time
    input.readOnly = false
    input.addEventListener('keyup', onKeyup)
    input.closest('form').addEventListener('submit', onSubmit)
  }

  const onListItemDblclick = (e) => {
    if (e.target.closest('[data-ref="samplenameInput"]')) {
      activateInput(e.target.closest('[data-ref="samplenameInput"]'))
    }
  }

  const onDragStart = (e) => {
    instance.setState('dragging', e.target)
    const input = e.target.querySelector('[data-ref="samplenameInput"]')

    e.dataTransfer.setData('text/plain', JSON.stringify({
      sampleId: Number(input.getAttribute('data-sample-id'))
    }))
  }

  const onDragEnd = (e) => {
    instance.setState('', e.target)
  }

  const onDragover = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const onDrop = (e) => {
    e.stopPropagation()
    e.preventDefault()

    if (e.dataTransfer.files.length) {
      instance.trigger('load', { files: e.dataTransfer.files })
    }
  }

  const bind = () => {
    bindEvent('click', instance.refs.loadSamplesTrigger, onLoadClick)
    bindEvent('click', instance.refs.purgeSamples, onPurgeSamplesClick)
    bindEvent('change', instance.refs.loadSamples, onLoadSamples)
    bindEvent('click', instance.refs.list, onListItemClick)
    bindEvent('dblclick', instance.refs.list, onListItemDblclick)

    bindEvent('dragstart', instance.refs.list, onDragStart)
    bindEvent('dragend', instance.refs.list, onDragEnd)
    bindEvent('dragover', instance.refs.list, onDragover)
    bindEvent('drop', instance.refs.list, onDrop)
  }

  // Public vars

  // Public methods
  instance.addSampleRows = (rows) => {
    // convert rows to an array which can be sorted
    rows = Object.entries(rows).map((row) => ({
      sampleId: Number(row[0]),
      fileName: row[1].fileName,
      sampleName: row[1].sampleName,
      size: getSize(row[1].size),
      duration: formatDuration(row[1].duration)
    }))

    // Sort by sampleName, ascending
    rows.sort((a, b) => {
      const nameA = a.sampleName.toUpperCase() // ignore upper and lowercase
      const nameB = b.sampleName.toUpperCase() // ignore upper and lowercase
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }

      // names must be equal
      return 0
    })

    console.log(rows)

    instance.refs.list.innerHTML = ''

    let rowsHtml = ''

    for (const r of rows) {
      rowsHtml += sampleRowView.tpl({
        sampleId: r.sampleId,
        filename: r.fileName,
        samplename: r.sampleName,
        size: r.size,
        duration: r.duration
      })
    }

    instance.refs.list.innerHTML = rowsHtml
  }

  instance.reset = () => {
    instance.refs.loadSamplesTrigger.disabled = true
    instance.refs.purgeSamples.disabled = true
    instance.refs.loadSamples.disabled = true
  }

  instance.init = () => {
    instance.refs.loadSamplesTrigger.disabled = false
    instance.refs.purgeSamples.disabled = false
    instance.refs.loadSamples.disabled = false
  }

  // Hooks
  instance.on('rendered', init)

  return instance
}

// Static methods
view.tpl = template

export {
  view as libraryView
}
