import { isWav } from 'js/utils/wav'
import { observer } from 'js/utils/observer'
import { round } from 'js/utils/round'
import { normalizeStr } from 'js/utils/normalize-str'
import { actions } from 'js/modules/actions'

const copyArraybuffer = (srcBuffer) => {
  const dstBuffer = new window.ArrayBuffer(srcBuffer.byteLength)
  new Uint8Array(dstBuffer).set(new Uint8Array(srcBuffer))
  return dstBuffer
}

const factory = () => {
  const instance = {}

  Object.assign(instance, observer())

  // Private vars
  let sampleId = -1
  let samples = {}

  // Private methods
  const loadSample = async (file) => {
    const arrayBuffer = await file.arrayBuffer()

    if (!isWav(arrayBuffer)) {
      throw new Error('File is not a wave file.')
    }

    const audioBuffer = await window.audiocontext.decodeAudioData(copyArraybuffer(arrayBuffer))

    sampleId++

    samples[sampleId] = {
      fileName: file.name,
      size: file.size,
      duration: round(audioBuffer.duration * 10 ** 3), // ms
      sampleName: normalizeStr(file.name.match(/(.*)\.wav$/i)[1]),
      audioBuffer,
      arrayBuffer
    }
  }

  // Public vars

  // Public methods

  /**
   * Play a single sample
   */
  instance.play = (sampleId) => {
    const audioSource = window.audiocontext.createBufferSource()
    audioSource.buffer = samples[sampleId].audioBuffer
    audioSource.connect(window.audiocontext.destination)
    audioSource.start()
  }

  instance.delete = (sampleId) => {
    delete samples[sampleId]

    instance.trigger('update', { samples })
  }

  instance.loadSamples = async (files) => {
    const promises = []

    for (let i = 0; i < files.length; i++) {
      promises.push(loadSample(files[i]))
    }

    await Promise.all(promises)

    await setTimeout(() => {
      instance.trigger('update', { samples })
      instance.trigger('load-end')
    }, 300)
  }

  instance.purgeSamples = () => {
    const pgmPads = actions.getProgramConfig().pads

    const isUsed = (sampleName) => {
      for (const pad of pgmPads) {
        for (const s of pad.samples) {
          if (s.sampleName === sampleName) {
            return true
          }
        }
      }

      return false
    }

    for (const s in samples) {
      if (Object.prototype.hasOwnProperty.call(samples, s)) {
        if (!isUsed(samples[s].sampleName)) {
          delete samples[s]
        }
      }
    }

    instance.trigger('update', { samples })
  }

  instance.clearSamples = () => {
    samples = {}
    sampleId = -1
    instance.trigger('update', { samples })
  }

  instance.getSampleId = (sampleName) => {
    if (sampleName === '') {
      return -1
    }

    for (const s in samples) {
      if (!Object.prototype.hasOwnProperty.call(samples, s)) {
        return
      }

      if (samples[s].sampleName === sampleName) {
        return Number(s)
      }
    }
  }

  instance.getSampleNameById = (sampleId) => {
    if (sampleId < 0) {
      return ''
    }

    return samples[sampleId].sampleName
  }

  instance.getSample = (sampleId) => samples[sampleId]

  instance.getReferencedSamples = (pgmConfig) => {
    const sampleBuffers = []

    // There are always 64 pdas
    for (let p = 64; p--;) {
      // There are always 4 sample slots per pad
      for (let s = 4; s--;) {
        if (pgmConfig.pads[p].samples[s].sampleName !== '') {
          const sample = samples[instance.getSampleId(pgmConfig.pads[p].samples[s].sampleName)]
          sampleBuffers.push({
            fileName: `${sample.sampleName}.WAV`,
            arrayBuffer: sample.arrayBuffer
          })
        }
      }
    }

    return sampleBuffers
  }

  instance.getSamplesDropdownList = () => {
    const list = [{
      value: -1,
      label: '(EMPTY)'
    }]

    for (const i in samples) {
      if (!Object.prototype.hasOwnProperty.call(samples, i)) {
        continue
      }

      list.push({
        value: Number(i),
        label: samples[i].sampleName
      })
    }

    list.sort((a, b) => {
      const nameA = a.label.toUpperCase() // ignore upper and lowercase
      const nameB = b.label.toUpperCase() // ignore upper and lowercase
      if (nameA < nameB) {
        return 1
      }
      if (nameA > nameB) {
        return -1
      }

      // names must be equal
      return 0
    })

    return list
  }

  instance.reset = () => {
    instance.clearSamples()
  }

  // Initialisation

  return instance
}

export {
  factory as libraryFactory
}
