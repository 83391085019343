/**
 * @function template
 *
 * @desc Renders template with given view model.
 *
 * @param {string} label - Label
 * @param {string} name
 * @param {Array} options - Array of option objects: value, label (, selected)
 * @param {string|number} value - The selected value
 * @param {string} ref
 * @returns {string} HTML string of component
 */
const template = ({ label, name, options = [], value, ref = '' }) => {
  return `
<label class="select-field">
    <span class="select-field__label">${label}</span>
    
    <span class="select-field__wrapper">
        <select class="select-field__control"${ref ? ' data-ref="' + ref + '"' : ''} name="${name}">
            ${view.optionsTpl(options, value)}
        </select>
    </span>
</label>
`
}

const view = {}

view.tpl = template
view.optionsTpl = (options, value) => {
  let optionsStr = ''

  for (let i = options.length; i--;) {
    if (value !== undefined && options[i].value === value) {
      options[i].selected = true
    }

    const option = options[i]

    optionsStr += `
<option
    value="${option.value}"
    ${option.selected ? 'selected' : ''}
    ${option.disabled ? 'disabled' : ''}
>${option.label}</option>`
  }

  return optionsStr
}

export {
  view as selectFieldView
}
