import { classes } from 'js/utils/v'

/**
 * @function template
 *
 * @desc Renders template with given view model.
 *
 * @param {Object} data - View model
 * @returns {string} HTML string of component
 */
const template = ({ label, type = 'submit', disabled = false, modifiers, ref }) => `
<button class="${classes('button', modifiers)}"
        ${ref ? ' data-ref="' + ref + '"' : ''} 
        ${disabled ? 'disabled' : ''}
        type="${type}">
    <span class="button__label">${label}</span>
    <span class="button__button"></span>
</button>
`

const view = {}

view.tpl = template

export {
  view as buttonView
}
