import { completeAssign } from 'js/utils/complete-assign'
import { merge } from 'js/utils/merge'
import { bindEvent, v } from 'js/utils/v'

// Defaults for view model
const defaults = {}

/**
 * @function template
 *
 * @desc Renders template with given view model.
 *
 * @returns {string} HTML string of component
 */
const template = () => `
<div class="sample-edit-select">
    <ul class="sample-edit-select__samples">
        <li class="sample-edit-select__item">
            <label class="sample-edit-select__sample button">
                <span class="sample-edit-select__name button__label">S 1</span>
                <input class="sample-edit-select__btn button__button sample-1" type="radio" value="0" name="sample-edit-select" data-ref="sampleEditSelect">
            </label>
        </li>
        
        <li class="sample-edit-select__item">
            <label class="sample-edit-select__sample button">
                <span class="sample-edit-select__name button__label">S 2</span>
                <input class="sample-edit-select__btn button__button sample-2" type="radio" value="1" name="sample-edit-select" data-ref="sampleEditSelect">
            </label>
        </li>
        
        <li class="sample-edit-select__item">
            <label class="sample-edit-select__sample button">
                <span class="sample-edit-select__name button__label">S 3</span>
                <input class="sample-edit-select__btn button__button sample-3" type="radio" value="2" name="sample-edit-select" data-ref="sampleEditSelect">
            </label>
        </li>
        
        <li class="sample-edit-select__item">
            <label class="sample-edit-select__sample button">
                <span class="sample-edit-select__name button__label">S 4</span>
                <input class="sample-edit-select__btn button__button sample-4" type="radio" value="3" name="sample-edit-select" data-ref="sampleEditSelect">
            </label>
        </li>
    </ul>
</div>
`

/**
 * View factory
 *
 * @param {string|Element} el - Selector or DOM element. This element will be replaced by the rendered component.
 * @param {Object} viewModel - View model
 * @param {Element|Document} [root] - This is only used when `el` is a selector.
 *
 * @returns {Object} View instance
 */
const view = (el, viewModel = {}, root = document) => {
  const vm = merge({}, defaults, viewModel)
  const instance = completeAssign({}, v({
    tpl: view.tpl,
    data: vm,
    mount: el,
    root
  }))

  // Private vars

  // Private methods
  const init = () => {
    bind()
  }

  const bind = () => {
    bindEvent('change', instance.refs.sampleEditSelect, (e) => {
      instance.trigger('select', { sampleNr: Number(e.target.value) })
    })
  }

  // Public vars

  // Public methods

  // Hooks
  instance.on('rendered', init)

  return instance
}

// Static methods
view.tpl = template

export {
  view as sampleEditSelectView
}
