import { pubsub } from 'js/modules/pubsub'

/**
 * Controller
 *
 * @param {Object} view - View instance
 * @param {Object} model - Model
 */
const controller = ({ view }) => {
  // Bindings
  pubsub.on('bank-select/set', ({ bankNr }) => {
    view.setActiveBank(bankNr)
  })

  // Render view
  view.render()
}

export {
  controller as padsController
}
