// https://mybunnyhug.org/fileformats/pgm/

import { midiNotes } from 'js/utils/midi-notes'

const ONE_SHOT = 0
const NOTE_ON = 1
const POLY = 0
const MONO = 1
const pgmSize = 10756 // bytes

// Default values
const LEVEL_DEFAULT = 100
const VELOCITY_TO_LEVEL_DEFAULT = 80

const BANKS = ['A', 'B', 'C', 'D']

const playModeDropdown = [
  {
    value: ONE_SHOT,
    label: 'One Shot'
  },
  {
    value: NOTE_ON,
    label: 'Note On'
  }
]

const voiceOverlapDropdown = [
  {
    value: POLY,
    label: 'Poly'
  },
  {
    value: MONO,
    label: 'Mono'
  }
]

const muteGroupDropdown = ['Off', ...[...Array(32).keys()].map(x => ++x)].map((v, idx) => ({
  value: idx,
  label: v
}))

const midiInDropdown = midiNotes.map((v, idx) => ({
  value: idx,
  label: `${v} (${idx})`
}))

// const padToMidiDefault = [
//   [37, 36, 42, 82, 40, 38, 46, 44, 48, 47, 45, 43, 49, 55, 51, 53], // Bank A
//   [54, 69, 81, 80, 65, 66, 76, 77, 56, 62, 63, 64, 73, 74, 71, 39], // Bank B
//   [52, 57, 58, 59, 60, 61, 67, 68, 70, 72, 75, 78, 79, 35, 41, 50], // Bank C
//   [83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98] // Bank D
// ].flat()

const padToMidiChromatic = [
  Array(16).fill(0).map((p, idx) => 36 + idx), // Bank A
  Array(16).fill(0).map((p, idx) => 52 + idx), // Bank B
  Array(16).fill(0).map((p, idx) => 68 + idx), // Bank C
  Array(16).fill(0).map((p, idx) => 84 + idx) // Bank D
].flat()

const midiToPadUnassigned = Array(128).fill(64)

const sampleInit = (name = '') => ({
  sampleId: -1, // This will not be written to the program file
  sampleName: name,
  level: LEVEL_DEFAULT,
  playMode: ONE_SHOT,
  tuning: 0,
  rangeLower: 0,
  rangeUpper: 127
})

const padsInit = () => {
  const pads = []

  for (let i = 0; i < 64; i++) {
    pads.push({
      samples: [
        sampleInit(),
        sampleInit(),
        sampleInit(),
        sampleInit()
      ],
      voiceOverlap: POLY,
      muteGroup: 0, // Off
      attack: 0,
      decay: 0,
      decayMode: 0,
      velocityToLevel: VELOCITY_TO_LEVEL_DEFAULT,
      filter1Type: 0,
      filter1Freq: 0,
      filter1Res: 0,
      filter1velocityToFreq: 0,
      filter2Type: 0,
      filter2Freq: 0,
      filter2Res: 0,
      filter2velocityToFreq: 0,
      mixerLevel: 100,
      mixerPan: 50,
      output: 0,
      fxSend: 0,
      fxSendLevel: 0,
      filterAttenuation: 0
    })
  }

  return pads
}

const sliderInit = () => ({
  pad: 0,
  parameter: 0,
  tuneLow: -120,
  tuneHigh: 120,
  filterLow: -50,
  filterHigh: 50,
  layerLow: 0,
  layerHigh: 127,
  attackLow: 0,
  attackHigh: 51,
  decayLow: 0,
  decayHigh: 61
})

const createProgramSkeleton = () => ({
  pads: padsInit(),
  midi: {
    // padToMidi: padToMidiDefault,
    padToMidi: padToMidiChromatic,
    midiToPad: midiToPadUnassigned,
    programChange: 0 // OFF
  },
  slider: [
    sliderInit(),
    sliderInit()
  ]
})

const ascii = (char) => char.charCodeAt(0)

const writeString = (str, view, offset) => {
  const strArray = str.toString().split('')

  for (let i = 0; i < strArray.length; i++) {
    view.setInt8(offset + i, ascii(strArray[i]))
  }
}

const getString = (view, offset, length) => {
  let str = ''

  for (let i = 0; i < length; i++) {
    // String is right-padded with 0x00.
    if (view.getInt8(offset + i) === 0x00) {
      break
    }

    str += String.fromCharCode(view.getInt8(offset + i))
  }

  return str
}

const writeHeader = (view) => {
  view.setUint16(0x00, 0x2A04, true) // File size
  view.setUint16(0x02, 0x00) // Padding
  writeString('MPC1000 PGM 1.00', view, 0x04)
  view.setUint32(0x14, 0x00) // Padding
}

const writeSampleData = (view, padIdx, samples) => {
  let offset

  for (let i = 0; i < samples.length; i++) {
    offset = 0x18 + (padIdx * 0xA4) + (i * 0x18)

    writeString(samples[i].sampleName, view, offset) // Sample name
    view.setUint8(offset + 0x11, samples[i].level) // Level
    view.setUint8(offset + 0x12, samples[i].rangeLower) // Range Lower
    view.setUint8(offset + 0x13, samples[i].rangeUpper) // Range Upper
    view.setInt16(offset + 0x14, samples[i].tuning, true) // Tuning
    view.setInt8(offset + 0x16, samples[i].playMode) // Play Mode
  }
}

const writePadData = (view, padIdx, padData) => {
  const offset = 0x18 + (padIdx * 0xA4)

  view.setInt8(offset + 0x62, padData.voiceOverlap) // Voice Overlap
  view.setInt8(offset + 0x63, padData.muteGroup) // Mute Group
  view.setInt8(offset + 0x65, 0x01) // Unknown
  view.setInt8(offset + 0x66, padData.attack) // Attack
  view.setInt8(offset + 0x67, padData.decay) // Decay
  view.setInt8(offset + 0x68, padData.decayMode) // Decay Mode
  view.setUint8(offset + 0x6B, padData.velocityToLevel) // Velocity To Level
  view.setInt8(offset + 0x71, padData.filter1Type) // Filter 1 Type
  view.setUint8(offset + 0x72, padData.filter1Freq) // Filter 1 Freq
  view.setUint8(offset + 0x73, padData.filter1Res) // Filter 1 Res
  view.setUint8(offset + 0x78, padData.filter1velocityToFreq) // Filter 1 Velocity to Frequency
  view.setInt8(offset + 0x79, padData.filter2Type) // Filter 2 Type
  view.setUint8(offset + 0x7A, padData.filter2Freq) // Filter 2 Freq
  view.setUint8(offset + 0x7B, padData.filter2Res) // Filter 2 Res
  view.setUint8(offset + 0x80, padData.filter2velocityToFreq) // Filter 2 Velocity to Frequency
  view.setUint8(offset + 0x8F, padData.mixerLevel) // Mixer Level
  view.setUint8(offset + 0x90, padData.mixerPan) // Mixer Pan
  view.setInt8(offset + 0x91, padData.output) // Output
  view.setInt8(offset + 0x92, padData.fxSend) // FX Send
  view.setUint8(offset + 0x93, padData.fxSendLevel) // FX Send Level
  view.setInt8(offset + 0x94, padData.filterAttenuation) // Filter Attenuation
}

const writeMidiData = (view, midiData) => {
  // Pad MIDI Note Values
  for (let i = 0; i < midiData.padToMidi.length; i++) {
    view.setUint8(0x2918 + i, midiData.padToMidi[i])
  }

  // MIDI Note Pad Values
  for (let i = 0; i < midiData.midiToPad.length; i++) {
    view.setUint8(0x2958 + i, midiData.midiToPad[i])
  }

  view.setUint8(0x29D8, midiData.programChange)
}

const writeSliderData = (view, sliderData) => {
  let offset

  for (let i = 0; i < sliderData.length; i++) {
    offset = i * 0x0D

    view.setUint8(offset + 0x29D9, sliderData[i].pad)
    view.setInt8(offset + 0x29DA, 0x01)
    view.setInt8(offset + 0x29DB, sliderData[i].parameter)
    view.setInt8(offset + 0x29DC, sliderData[i].tuneLow)
    view.setInt8(offset + 0x29DD, sliderData[i].tuneHigh)
    view.setInt8(offset + 0x29DE, sliderData[i].filterLow)
    view.setInt8(offset + 0x29DF, sliderData[i].filterHigh)
    view.setInt8(offset + 0x29E0, sliderData[i].layerLow)
    view.setInt8(offset + 0x29E1, sliderData[i].layerHigh)
    view.setInt8(offset + 0x29E2, sliderData[i].attackLow)
    view.setInt8(offset + 0x29E3, sliderData[i].attackHigh)
    view.setInt8(offset + 0x29E4, sliderData[i].decayLow)
    view.setInt8(offset + 0x29E5, sliderData[i].decayHigh)
  }
}

const writePadsSamplesData = (view, pads) => {
  for (let i = 0; i < pads.length; i++) {
    writeSampleData(view, i, pads[i].samples)
    writePadData(view, i, pads[i])
  }
}

const writeProgramToBuffer = (pgm) => {
  const fileBuffer = new window.ArrayBuffer(pgmSize)
  const fileView = new window.DataView(fileBuffer)

  writeHeader(fileView)
  writePadsSamplesData(fileView, pgm.pads)
  writeMidiData(fileView, pgm.midi)
  writeSliderData(fileView, pgm.slider)

  return fileBuffer
}

const parseSampleData = (view, padIdx, samples) => {
  let offset

  // There's always 4 sample slots
  for (let i = 0; i < 4; i++) {
    offset = 0x18 + (padIdx * 0xA4) + (i * 0x18)

    samples[i].sampleName = getString(view, offset, 16)
    samples[i].level = view.getUint8(offset + 0x11)
    samples[i].rangeLower = view.getUint8(offset + 0x12)
    samples[i].rangeUpper = view.getUint8(offset + 0x13)
    samples[i].tuning = view.getInt16(offset + 0x14, true)
    samples[i].playMode = view.getUint8(offset + 0x16)
  }
}

const parsePadData = (view, padIdx, padData) => {
  const offset = 0x18 + (padIdx * 0xA4)

  padData.voiceOverlap = view.getInt8(offset + 0x62) // Voice Overlap
  padData.muteGroup = view.getInt8(offset + 0x63) // Mute Group
  padData.attack = view.getInt8(offset + 0x66) // Attack
  padData.decay = view.getInt8(offset + 0x67) // Decay
  padData.decayMode = view.getInt8(offset + 0x68) // Decay Mode
  padData.velocityToLevel = view.getUint8(offset + 0x6B) // Velocity To Level
  padData.filter1Type = view.getInt8(offset + 0x71) // Filter 1 Type
  padData.filter1Freq = view.getUint8(offset + 0x72) // Filter 1 Freq
  padData.filter1Res = view.getUint8(offset + 0x73) // Filter 1 Res
  padData.filter1velocityToFreq = view.getUint8(offset + 0x78) // Filter 1 Velocity to Frequency
  padData.filter2Type = view.getInt8(offset + 0x79) // Filter 2 Type
  padData.filter2Freq = view.getUint8(offset + 0x7A) // Filter 2 Freq
  padData.filter2Res = view.getUint8(offset + 0x7B) // Filter 2 Res
  padData.filter2velocityToFreq = view.getUint8(offset + 0x80) // Filter 2 Velocity to Frequency
  padData.mixerLevel = view.getUint8(offset + 0x8F) // Mixer Level
  padData.mixerPan = view.getUint8(offset + 0x90) // Mixer Pan
  padData.output = view.getInt8(offset + 0x91) // Output
  padData.fxSend = view.getInt8(offset + 0x92) // FX Send
  padData.fxSendLevel = view.getUint8(offset + 0x93) // FX Send Level
  padData.filterAttenuation = view.getInt8(offset + 0x94) // Filter Attenuation
}

const parsePadsSamplesData = (view, pads) => {
  // Theres always 64 pads
  for (let i = 0; i < 64; i++) {
    parseSampleData(view, i, pads[i].samples)
    parsePadData(view, i, pads[i])
  }
}

const parseMidiData = (view, midiData) => {
  // Pad MIDI Note Values
  // There's always 64 values (for 64 pads
  for (let i = 0; i < 64; i++) {
    midiData.padToMidi[i] = view.getUint8(0x2918 + i)
  }

  // MIDI Note Pad Values
  // There's always 128 values (for 128 midi notes)
  for (let i = 0; i < 128; i++) {
    midiData.midiToPad[i] = view.getUint8(0x2958 + i)
  }

  midiData.programChange = view.getUint8(0x29D8)
}

const parseSliderData = (view, sliderData) => {
  let offset

  // Just 2 sliders
  for (let i = 0; i < 2; i++) {
    offset = i * 0x0D

    sliderData[i].pad = view.getUint8(offset + 0x29D9)
    sliderData[i].parameter = view.getInt8(offset + 0x29DB)
    sliderData[i].tuneLow = view.getInt8(offset + 0x29DC)
    sliderData[i].tuneHigh = view.getInt8(offset + 0x29DD)
    sliderData[i].filterLow = view.getInt8(offset + 0x29DE)
    sliderData[i].filterHigh = view.getInt8(offset + 0x29DF)
    sliderData[i].layerLow = view.getInt8(offset + 0x29E0)
    sliderData[i].layerHigh = view.getInt8(offset + 0x29E1)
    sliderData[i].attackLow = view.getInt8(offset + 0x29E2)
    sliderData[i].attackHigh = view.getInt8(offset + 0x29E3)
    sliderData[i].decayLow = view.getInt8(offset + 0x29E4)
    sliderData[i].decayHigh = view.getInt8(offset + 0x29E5)
  }
}

const validatePgmFile = (pgmBuffer) => {
  const pgmView = new window.DataView(pgmBuffer)

  // Is correct file size set?
  // Is file descriptor set?
  return pgmView.getUint16(0x00, true) === 0x2A04 && (getString(pgmView, 0x04, 16) === 'MPC1000 PGM 1.00' || getString(pgmView, 0x04, 16) === 'MPC1000 PGM 1.01' || getString(pgmView, 0x04, 16) === 'MPC1000 PGM 6.00')
}

const parseProgram = (pgmBuffer) => {
  if (!validatePgmFile(pgmBuffer)) {
    throw new Error('File is not a PGM file.')
  }

  const pgmView = new window.DataView(pgmBuffer)
  const pgm = createProgramSkeleton()

  parsePadsSamplesData(pgmView, pgm.pads)
  parseMidiData(pgmView, pgm.midi)
  parseSliderData(pgmView, pgm.slider)

  return pgm
}

export {
  createProgramSkeleton,
  writeProgramToBuffer,
  BANKS,
  playModeDropdown,
  voiceOverlapDropdown,
  muteGroupDropdown,
  midiInDropdown,
  NOTE_ON,
  parseProgram
}
