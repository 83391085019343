import { completeAssign } from 'js/utils/complete-assign'
import { merge } from 'js/utils/merge'
import { bindEvent, v } from 'js/utils/v'
import { fixTypes } from 'js/utils/fix-types'
import { selectFieldView } from 'components/base/select-field/select-field.view'
import { inputFieldView } from 'components/base/input-field/input-field.view'
import { playModeDropdown } from 'js/modules/program'
import { actions } from 'js/modules/actions'

// Defaults for view model
const defaults = {}

/**
 * @function template
 *
 * @desc Renders template with given view model.
 *
 * @param {Object} data - View model
 * @returns {string} HTML string of component
 */
const template = (data) => `
<div class="sample-edit">
    <form data-ref="sampleParams">
        <div class="form-row">
            <div class="form-col half" data-ref="sampleSelectContainer">
                ${selectFieldView.tpl({
  label: 'Sample',
  name: 'sampleId',
  options: [],
  value: data.sampleId,
  ref: 'sampleSelect'
})}
            </div>
            
            <div class="form-col half">
                <div class="form-col quarter">
                    ${inputFieldView.tpl({
  label: 'Level',
  name: 'level',
  type: 'number',
  value: data.level,
  min: 0,
  max: 100,
  ref: 'levelInput'
})}
                </div>
                <div class="form-col quarter">
                    ${inputFieldView.tpl({
  label: 'Tuning',
  name: 'tuning',
  type: 'number',
  value: data.tuning,
  min: -3600,
  max: 3600,
  step: 100,
  ref: 'tuningInput'
})}
                </div>
            </div>
        </div>
        
        <div class="form-row">
            <div class="form-col half">
                ${selectFieldView.tpl({
  label: 'Play mode',
  name: 'playMode',
  value: data.playMode,
  options: playModeDropdown,
  ref: 'playModeSelect'
})}
            </div>
            
            <div class="form-col half">
                <div class="form-col quarter">
                    ${inputFieldView.tpl({
  label: 'r Lower',
  title: 'Range Lower',
  name: 'rangeLower',
  type: 'number',
  value: data.rangeLower,
  min: 0,
  max: 127,
  ref: 'rangeLowerInput'
})}
                </div>
                <div class="form-col quarter">
                ${inputFieldView.tpl({
  label: 'r upper',
  title: 'Range Upper',
  name: 'rangeUpper',
  type: 'number',
  value: data.rangeUpper,
  min: 0,
  max: 127,
  ref: 'rangeUpperInput'
})}
                </div>
            </div>
        </div>
    </form>
</div>
`

/**
 * View factory
 *
 * @param {string|Element} el - Selector or DOM element. This element will be replaced by the rendered component.
 * @param {Object} viewModel - View model
 * @param {Element|Document} [root] - This is only used when `el` is a selector.
 *
 * @returns {Object} View instance
 */
const view = (el, viewModel = {}, root = document) => {
  const vm = merge({}, defaults, viewModel)
  const instance = completeAssign({}, v({
    tpl: view.tpl,
    data: vm,
    mount: el,
    root
  }))

  // Private vars
  let currentPadIdx
  let globalEdit = false

  // Private methods
  const init = () => {
    bind()
  }

  const saveSampleParams = () => {
    const sampleParams = Object.fromEntries(new window.FormData(instance.refs.sampleParams))
    fixTypes(sampleParams)

    instance.trigger('save', {
      sampleNr: vm.sampleNr,
      padIdx: currentPadIdx,
      sampleParams,
      globalEdit
    })
  }

  const onKeydown = (e) => {
    console.log(e.code)
    if (e.code === 'F5') {
      globalEdit = !globalEdit

      instance.el.classList.toggle('global-edit', globalEdit)
    }
  }

  const bind = () => {
    bindEvent('change', instance.refs.sampleParams, saveSampleParams)
    bindEvent('submit', instance.refs.sampleParams, (e) => {
      e.preventDefault()
    })

    document.addEventListener('keydown', onKeydown)
  }

  // Public vars

  // Public methods
  instance.update = (sampleConfig) => {
    instance.refs.sampleSelect.value = sampleConfig.sampleId
    instance.refs.playModeSelect.value = sampleConfig.playMode
    instance.refs.levelInput.value = sampleConfig.level
    instance.refs.tuningInput.value = sampleConfig.tuning
    instance.refs.rangeLowerInput.value = sampleConfig.rangeLower
    instance.refs.rangeUpperInput.value = sampleConfig.rangeUpper

    instance.refs.sampleSelectContainer.innerHTML = selectFieldView.tpl({
      label: 'Sample',
      name: 'sampleId',
      value: sampleConfig.sampleId,
      options: actions.getSamplesDropdown(),
      ref: 'sampleSelect'
    })
  }

  instance.updateSampleSelect = () => {
    const sampleSelect = instance.refs.sampleSelectContainer.querySelector('[data-ref="sampleSelect"]')

    sampleSelect.innerHTML = selectFieldView.optionsTpl(actions.getSamplesDropdown(), Number(sampleSelect.value))
  }

  instance.setCurrentPadIdx = (padIdx) => {
    currentPadIdx = padIdx
  }

  // Hooks
  instance.on('rendered', init)

  return instance
}

// Static methods
view.tpl = template

export {
  view as sampleEditView
}
