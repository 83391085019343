/**
 * @function template
 *
 * @desc Renders template with given view model.
 *
 * @param {string} title - Title
 * @returns {string} HTML string of component
 */
const template = ({ title }) => `
<div class="section-title">
    <span class="section-title__title">${title}</span>
</div>
`

const view = {}

view.tpl = template

export {
  view as sectionTitleView
}
