import { pubsub } from 'js/modules/pubsub'

/**
 * Controller
 *
 * @param {Object} view - View instance
 * @param {Object} model - Model
 */
const controller = ({ view, model }) => {
  // Bindings
  view.on('play-sample', ({ sampleId }) => {
    model.play(sampleId)
  })

  view.on('delete-sample', ({ sampleId }) => {
    model.delete(sampleId)
  })

  view.on('load', ({ files }) => {
    model.loadSamples(files).then()
  })

  view.on('purge', () => {
    model.purgeSamples()
  })

  model.on('update', ({ samples }) => {
    view.addSampleRows(samples)
    pubsub.trigger('library/update')
  })

  pubsub.on('app/reset', () => {
    model.reset()
    view.reset()
  })

  pubsub.on('app/init', () => {
    view.init()
  })

  // Render view
  view.render()
}

export {
  controller as libraryController
}
