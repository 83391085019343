export const fixTypes = (obj) => {
  for (const i in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, i)) {
      if (obj[i] === '') {
        continue
      }

      // Convert valid number strings to numbers
      obj[i] = !isNaN(obj[i]) ? Number(obj[i]) : obj[i]
    }
  }
}
