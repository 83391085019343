import { observer } from 'js/utils/observer'
import { compareArrays } from 'js/utils/compare-arrays'
import { pubsub } from 'js/modules/pubsub'

let componentsMapping = {}
let cId = -1
let mId = -1
let aId = -1
let delegates = {}

const checkRoot = (root) => {
  if (root instanceof window.Document || root instanceof window.HTMLElement) {
    return root
  } else if (typeof root === 'string') {
    const tmp = document.querySelector(root)

    if (!tmp) {
      throw new Error(`Element with selector '${root}' not found.`)
    } else {
      return tmp
    }
  } else {
    throw new Error('"root" is not a DOM element.')
  }
}

const checkMount = (mount, rootEl) => {
  if (mount instanceof window.HTMLElement) {
    return mount
  } else if (typeof mount === 'string') {
    const tmp = rootEl.querySelector(mount)

    if (!tmp) {
      throw new Error(`Element with selector '${mount}' not found.`)
    } else {
      return tmp
    }
  } else {
    throw new Error('"mount" is not a DOM element.')
  }
}

const checkTpl = (tpl, data = {}) => {
  if (typeof tpl === 'function') {
    return tpl(data)
  } else if (typeof tpl === 'string') {
    return tpl
  } else {
    throw new Error('"tpl" is not a template.')
  }
}

export const classes = (name, mods = '') => [name, mods.split(' ').map((c) => c ? `${name}--${c}` : '').join(' ')].join(' ').trim()

/**
 *
 * @param {Object} componentRef
 * @param {string} componentRef.type
 * @param {Element} componentRef.el
 * @param {Object} componentRef.data
 * @returns {*}
 */
export const createView = ({ type, el, data }) => componentsMapping[type](el, data)

export const setComponentsMapping = (mapping) => {
  componentsMapping = mapping
}

export const placeholder = (type, { data, ref } = {}) => `
<div data-comp-type="${type}"${data ? ' data-data=\'' + JSON.stringify(data) + '\'' : ''}${ref ? ' data-ref=\'' + ref + '\'' : ''}></div>
`

export const render = ({ tpl, mount, data = {}, root = document, replaceNode = true }) => {
  const tmpEl = document.createElement('div')

  const rootEl = checkRoot(root)
  const mountEl = checkMount(mount, rootEl)

  cId++

  tmpEl.innerHTML = checkTpl(tpl, data)
  tmpEl.firstElementChild.setAttribute('data-c-id', 'c' + cId)

  if (replaceNode) {
    // Render by replacing mount node with component node.
    const parentNode = mountEl.parentNode

    // Render the template
    mountEl.replaceWith(tmpEl.firstElementChild)

    // Return newly inserted component element
    return parentNode.querySelector(`[data-c-id="c${cId}"]`)
  } else {
    // Render component inside mount node.
    const parentNode = mountEl

    // First clear node
    parentNode.innerHTML = ''

    // Render template and return it
    return parentNode.appendChild(tmpEl.firstElementChild)
  }
}

/**
 * View factory base
 *
 * Available lifecycle hooks:
 * - created
 * - rendered
 *
 * @param {string|function} tpl
 * @param {string|Element} mount
 * @param {object} data
 * @param {Object} [root=document] - Is only used when `mount` is a selector string.
 * @param {boolean} [isModule=false]
 * @param {boolean} [isApp=false]
 *
 * @returns {Object} View instance
 */
export const v = ({ tpl, mount, data, root = document, isModule = false, isApp = false }) => {
  // Fail early if mount, root or tpl is not valid
  checkMount(mount, checkRoot(root))
  checkTpl(tpl, data)

  const instance = {}
  Object.assign(instance, observer())

  // Private properties
  let el
  const refs = {}

  // Public properties
  Object.defineProperty(instance, 'el', {
    get: () => el,
    enumerable: true
  })

  Object.defineProperty(instance, 'refs', {
    get: () => refs,
    enumerable: true
  })

  // Public methods
  /**
   * Set state for the component.
   * If ref is given the state is set to the found refs.
   *
   * @param {string} state
   * @param {*} [target]
   */
  instance.setState = (state, target) => {
    const el = target === undefined ? instance.el : target

    if (state === '') {
      el.removeAttribute('data-state')
    } else {
      el.setAttribute('data-state', state)
    }
  }

  /**
   * Render method
   *
   * @param {boolean} replaceNode
   */
  instance.render = (replaceNode = true) => {
    el = render({
      tpl,
      data,
      mount,
      root,
      replaceNode
    })

    if (isModule) {
      mId++
      el.setAttribute('data-m-id', 'm' + mId)
    }

    if (isApp) {
      aId++
      el.setAttribute('data-app-id', 'a' + aId)
    }

    // Check for refs
    Array.from(el.querySelectorAll('[data-ref]')).forEach((el) => {
      const refName = el.getAttribute('data-ref')
      let ref

      if (el.hasAttribute('data-comp-type')) {
        ref = {
          el,
          type: el.getAttribute('data-comp-type'),
          data: JSON.parse(el.getAttribute('data-data'))
        }
      } else {
        ref = el
      }

      if (!Object.prototype.hasOwnProperty.call(refs, refName)) {
        refs[refName] = ref
      } else {
        if (!Array.isArray(refs[refName])) {
          refs[refName] = [refs[refName]]
        }

        refs[refName].push(ref)
      }
    })

    instance.trigger('rendered')

    return instance
  }

  instance.trigger('created')

  pubsub.on('destroy', () => {
    instance.trigger('beforeDestroy')
  })

  return instance
}

/**
 *
 * @param {string} eventName
 * @param {Array|Element} el
 * @param {Function} listener
 */
export const bindEvent = (eventName, el, listener) => {
  if (el instanceof window.HTMLElement) {
    el = [el]
  }

  const moduleRoot = el[0].closest('[data-m-id]') || el[0].closest('[data-c-id]')
  const id = moduleRoot.hasAttribute('data-m-id') ? moduleRoot.getAttribute('data-m-id') : moduleRoot.getAttribute('data-c-id')

  delegates[id] = delegates[id] || {}

  if (!delegates[id][eventName]) {
    delegates[id][eventName] = []
    moduleRoot.addEventListener(eventName, (e) => {
      const target = e.target
      for (let i = delegates[id][eventName].length; i--;) {
        for (let j = delegates[id][eventName][i].el.length; j--;) {
          if (delegates[id][eventName][i].el[j].contains(target)) {
            delegates[id][eventName][i].listener.call(null, e)
            break
          }
        }
      }
    })
  }

  delegates[id][eventName].push({
    el,
    listener
  })
}

/**
 *
 * @param {string} eventName
 * @param {*} el - DOM element
 * @param {Function} [listener]
 */
// TODO: This method does not work correctly. Maybe necessary to fix 'bindEvent' too.
export const unbindEvent = (eventName, el, listener) => {
  if (el instanceof window.HTMLElement) {
    el = [el]
  }

  const moduleRoot = el[0].closest('[data-m-id]') || el[0].closest('[data-c-id]')
  const id = moduleRoot.hasAttribute('data-m-id') ? moduleRoot.getAttribute('data-m-id') : moduleRoot.getAttribute('data-c-id')

  if (id && delegates[id]) {
    for (let i = delegates[id][eventName].length; i--;) {
      if (compareArrays(delegates[id][eventName][i].el, el)) {
        delegates[id][eventName].splice(i, 1)
        break
      }
    }

    if (!delegates[id][eventName].length) {
      delete delegates[id][eventName]
    }

    // Remove entire entry when there are no more delegates.
    if (!Object.keys(delegates[id]).length) {
      delete delegates[id]
    }
  }
}

export const unbindModuleEvents = (id) => {
  if (id && delegates[id]) {
    delete delegates[id]
  }
}

export const clearDelegates = () => {
  delegates = {}
}

// @if NODE_ENV='development'
window.DM = window.DM || {}
window.DM.delegates = delegates
// @endif
