import { completeAssign } from 'js/utils/complete-assign'
import { merge } from 'js/utils/merge'
import { bindEvent, v } from 'js/utils/v'

// Defaults for view model
const defaults = {}

/**
 * @function template
 *
 * @desc Renders template with given view model.
 *
 * @param {Object} data - View model
 * @returns {string} HTML string of component
 */
const template = ({ padNr, bankNr, note = '', sampleName1 = '' }) => `
<div class="pad" data-bank="${bankNr}">
    <span class="pad__name tx-label">pad${padNr + 1}</span>
    
    <button class="pad__pad" data-ref="pad">
        <span class="pad__main-layer">
            <span class="pad__sample-name sample1 tx-mono-small" data-ref="sampleName">${sampleName1}</span>
            <span class="pad__sample-name sample2 tx-mono-small" data-ref="sampleName">${sampleName1}</span>
            <span class="pad__sample-name sample3 tx-mono-small" data-ref="sampleName">${sampleName1}</span>
            <span class="pad__sample-name sample4 tx-mono-small" data-ref="sampleName">${sampleName1}</span>
            
            <span class="pad__sample-level sample1" data-ref="sampleLevel"></span>
            <span class="pad__sample-level sample2" data-ref="sampleLevel"></span>
            <span class="pad__sample-level sample3" data-ref="sampleLevel"></span>
            <span class="pad__sample-level sample4" data-ref="sampleLevel"></span>
            
            <span class="pad__note tx-mono-small" data-ref="note">${note}</span>
        </span>
    </button>
</div>
`

/**
 * View factory
 *
 * @param {string|Element} el - Selector or DOM element. This element will be replaced by the rendered component.
 * @param {Object} viewModel - View model
 * @param {Element|*} [root=document] - This is only used when `el` is a selector.
 *
 * @returns {Object} View instance
 */
const view = (el, viewModel = {}, root = document) => {
  const vm = merge({}, defaults, viewModel)
  const instance = completeAssign({}, v({
    tpl: view.tpl,
    data: vm,
    mount: el,
    root
  }))

  // Private vars

  // Private methods
  const init = () => {
    bind()
  }

  const onPadClick = () => {
    instance.trigger('click')

    instance.noteOn()
  }

  const onPadPointerup = () => {
    instance.trigger('pointer-up')

    instance.noteOff()
  }

  const onDragover = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const onDropSample = (e) => {
    e.stopPropagation()
    e.preventDefault()

    const sampleParams = JSON.parse(e.dataTransfer.getData('text/plain'))

    instance.trigger('drop-sample', { sampleParams })

    return false
  }

  const bind = () => {
    bindEvent('pointerdown', instance.refs.pad, onPadClick)
    bindEvent('pointerup', instance.refs.pad, onPadPointerup)

    instance.el.addEventListener('drop', onDropSample, false)
    instance.el.addEventListener('dragover', onDragover)
  }

  // Public vars

  // Public methods
  instance.update = ({ note, sampleNames, sampleLevels }) => {
    instance.refs.sampleName.forEach((sn, idx) => {
      sn.innerHTML = sampleNames[idx]
    })

    instance.refs.sampleLevel.forEach((sl, idx) => {
      sl.style.transform = `scaleY(${sampleLevels[idx] / 100})`

      if (sampleNames[idx] === '') {
        sl.classList.add('no-sample')
      } else {
        sl.classList.remove('no-sample')
      }
    })

    instance.refs.note.innerHTML = note
  }

  instance.noteOn = () => {
    instance.setState('note-on')
  }

  instance.noteOff = () => {
    instance.setState('')
  }

  instance.highlightSample = (sampleNr) => {
    instance.setState('highlight', instance.refs.sampleName[sampleNr])

    setTimeout(() => {
      instance.setState('', instance.refs.sampleName[sampleNr])
    }, 60)
  }

  // Hooks
  instance.on('rendered', init)

  return instance
}

// Static methods
view.tpl = template

export {
  view as padView
}
