/**
 * Write File to given directory
 *
 * @param {string} filename
 * @param data
 * @param dirHandle
 * @returns {Promise<void>}
 */
export const writeFile = async (filename, data, dirHandle) => {
  const fileHandle = await dirHandle.getFileHandle(filename, { create: true })
  const fileWritable = await fileHandle.createWritable()
  await fileWritable.write(data)
  fileWritable.close()
}
