import { completeAssign } from 'js/utils/complete-assign'
import { merge } from 'js/utils/merge'
import { v } from 'js/utils/v'
import { midiNotes } from 'js/utils/midi-notes'

// Defaults for view model
const defaults = {}

/**
 * @function template
 *
 * @desc Renders template with given view model.
 *
 * @param {Object} data - View model
 * @returns {string} HTML string of component
 */
const template = (data) => `
<div class="midi-monitor">
    <table class="midi-monitor__table">
        <thead>
            <th class="tx-label-small">Midi Note</th>
            <th class="tx-label-small">Velocity</th>
        </thead>
        
        <tbody data-ref="history"></tbody>
    </table>
</div>
`

const dataRow = (note, velocity) => `
<tr>
    <td>${note}</td>
    <td>${velocity}</td>
</tr>
`

/**
 * View factory
 *
 * @param {string|Element} el - Selector or DOM element. This element will be replaced by the rendered component.
 * @param {Object} viewModel - View model
 * @param {Element|*} [root=document] - This is only used when `el` is a selector.
 *
 * @returns {Object} View instance
 */
const view = (el, viewModel = {}, root = document) => {
  const vm = merge({}, defaults, viewModel)
  const instance = completeAssign({}, v({
    tpl: view.tpl,
    data: vm,
    mount: el,
    root
  }))

  // Private vars

  // Private methods
  const init = () => {
    bind()
  }

  const bind = () => {}

  // Public vars

  // Public methods
  instance.addRow = (note, velocity) => {
    const noteStr = `${note} ${midiNotes[note]}`

    if (instance.refs.history.childElementCount >= 3) {
      instance.refs.history.removeChild(instance.refs.history.lastElementChild)
    }

    instance.refs.history.innerHTML = dataRow(noteStr, velocity) + instance.refs.history.innerHTML
  }

  // Hooks
  instance.on('rendered', init)

  return instance
}

// Static methods
view.tpl = template

export {
  view as midiMonitorView
}
