import { pubsub } from 'js/modules/pubsub'

const instance = {}

const NOTEON = 9
const NOTEOFF = 8
const CONTROLCHANGE = 11
const PITCHBEND = 14
const cc = {
  SUSTAIN: 64,
  MOD: 1
}

const onControlChange = (controllerNumber, data2) => {
  // Sustain
  if (controllerNumber === cc.SUSTAIN) {
    if (data2 >= 64) {
      pubsub.trigger('midi/sustainOn')
    }

    if (data2 <= 63) {
      pubsub.trigger('midi/sustainOff')
    }
  }
}

const onMIDIMessage = message => {
  const data = message.data // this gives us our [command/channel, note, velocity] data.

  let cmd = data[0] >> 4
  const channel = data[0] & 0xf
  const data1 = data[1]
  const data2 = data[2]

  // a velocity of 0 also means noteOff
  cmd = cmd === NOTEON && data2 === 0 ? NOTEOFF : cmd

  switch (cmd) {
    case NOTEON: {
      pubsub.trigger('midi/noteOn', {
        channel: channel,
        note: data1,
        velocity: data2
      })

      break
    }

    case NOTEOFF: {
      pubsub.trigger('midi/noteOff', {
        channel: channel,
        note: data1,
        velocity: data2
      })

      break
    }

    case PITCHBEND: {
      pubsub.trigger('midi/pitchBend', {
        channel: channel,
        pitch: data2
      })

      break
    }

    case CONTROLCHANGE: {
      onControlChange(data1, data2)
    }
  }
}

const onMIDISuccess = (midi) => {
  const inputs = midi.inputs.values()

  // loop over all available inputs and listen for any MIDI input
  for (let input = inputs.next(); input && !input.done; input = inputs.next()) {
    // each time there is a midi message call the onMIDIMessage function
    input.value.onmidimessage = onMIDIMessage
  }
}

const onMIDIFailure = (e) => {
  // when we get a failed response, run this code
  console.log('No access to MIDI devices or your browser doesn\'t support WebMIDI API. Please use WebMIDIAPIShim.' + e)
}

instance.init = () => {
  if (navigator.requestMIDIAccess) {
    navigator.requestMIDIAccess({
      sysex: false
    }).then(onMIDISuccess, onMIDIFailure)
  } else {
    console.error('No MIDI support in your browser.')
  }
}

export {
  instance as midi
}
