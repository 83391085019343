import { classes } from 'js/utils/v'

/**
 * @function template
 *
 * @desc Renders template with given view model.
 *
 * @param {Object} data - View model
 * @returns {string} HTML string of component
 */
const template = ({ label, name, type = 'text', value, required = false, disabled = false, readonly = false, min, max, step, minlength, maxlength, title, modifiers, ref = '' }) => `
<label class="${classes('input-field', modifiers)}" ${title !== undefined ? 'title="' + title + '"' : ''}>
    ${label ? '<span class="input-field__label">' + label + '</span>' : ''}
    
    <span class="input-field__wrapper">
        <input class="input-field__control"
            ${ref ? ' data-ref="' + ref + '"' : ''} 
            name="${name}"
            type="${type}"
            ${required ? 'required' : ''}
            ${disabled ? 'disabled' : ''}
            ${readonly ? 'readOnly' : ''}
            ${value !== undefined ? 'value="' + value + '"' : ''}
            ${min !== undefined ? 'min="' + min + '"' : ''}
            ${max !== undefined ? 'max="' + max + '"' : ''}
            ${step !== undefined ? 'step="' + step + '"' : ''}
            ${minlength !== undefined ? 'minlength="' + minlength + '"' : ''}
            ${maxlength !== undefined ? 'maxlength="' + maxlength + '"' : ''}
            />   
    </span>
</label>
`

const view = {}

view.tpl = template

export {
  view as inputFieldView
}
