import { observer } from 'js/utils/observer'

const pubsub = observer()

// @if NODE_ENV='development'
window.DM = window.DM || {}
window.DM.pubsubEvents = () => pubsub._events()
// @endif

export {
  pubsub
}
