import { pubsub } from '../../js/modules/pubsub'

/**
 * Controller
 *
 * @param {Object} view - View instance
 * @param {Object} model - Model
 */
const controller = ({ view }) => {
  // Bindings
  pubsub.on('midi/noteOn', ({ note, velocity }) => {
    view.addRow(note, velocity)
  })

  // Render view
  view.render()
}

export {
  controller as midiMonitorController
}
