import { actions } from 'js/modules/actions'
import { translateValue } from 'js/utils/translate-value'
import { NOTE_ON } from 'js/modules/program'
import { observer } from 'js/utils/observer'

const factory = () => {
  const instance = {}

  Object.assign(instance, observer())

  // Private vars
  const noteOnPlayMode = {}

  // Private methods

  instance.playNote = (note, velocity) => {
    const padToMidi = actions.getProgramConfig().midi.padToMidi
    const padIdx = padToMidi.indexOf(note)

    if (padIdx < 0) {
      return
    }

    instance.playPad(padIdx, velocity)
  }

  instance.stopPad = (padIdx) => {
    const stop = ({ adsrNode }) => {
      const t = window.audiocontext.currentTime
      adsrNode.gain.cancelScheduledValues(t)
      adsrNode.gain.setValueAtTime(adsrNode.gain.value, t)
      adsrNode.gain.setTargetAtTime(0, t, 0.001)

      delete noteOnPlayMode[padIdx]

      const stopIntervalId = setInterval(() => {
        if (adsrNode.gain.value < 0.001) {
          delete noteOnPlayMode[padIdx]
          clearInterval(stopIntervalId)
        }
      }, 10)
    }

    if (Object.prototype.hasOwnProperty.call(noteOnPlayMode, padIdx)) {
      for (let i = noteOnPlayMode[padIdx].length; i--;) {
        stop(noteOnPlayMode[padIdx][i])
      }
    }
  }

  instance.getPadIdx = (note) => {
    const padToMidi = actions.getProgramConfig().midi.padToMidi
    return padToMidi.indexOf(note)
  }

  instance.playPad = (padIdx, velocity = 127) => {
    if (padIdx < 0) {
      return
    }

    const padConfig = actions.getProgramConfig().pads[padIdx]

    for (let s = 4; s--;) {
      if (padConfig.samples[s].sampleName !== '') {
        if (velocity < padConfig.samples[s].rangeLower || velocity > padConfig.samples[s].rangeUpper) {
          continue
        }

        const sample = actions.getSample(actions.getSampleId(padConfig.samples[s].sampleName))
        const velocityFactor = translateValue(velocity, 0, 127, 0, 1, 5)

        const audioSource = window.audiocontext.createBufferSource()
        audioSource.buffer = sample.audioBuffer

        const adsrNode = window.audiocontext.createGain()

        if (padConfig.samples[s].playMode === NOTE_ON) {
          noteOnPlayMode[padIdx] = noteOnPlayMode[padIdx] || []
          noteOnPlayMode[padIdx].push({
            adsrNode
          })
        }

        // Set tuning
        audioSource.detune.value = padConfig.samples[s].tuning

        // Sample Level (with linear velocity applied)
        const sampleLevelNode = window.audiocontext.createGain()
        sampleLevelNode.gain.value = (padConfig.samples[s].level / 100) * velocityFactor

        // Mixer Level
        const mixerLevelNode = window.audiocontext.createGain()
        mixerLevelNode.gain.value = padConfig.mixerLevel / 100

        // Mixer Pan
        const mixerPanNode = window.audiocontext.createStereoPanner()
        mixerPanNode.pan.value = translateValue(padConfig.mixerPan, 0, 100, -1, 1, 5)

        // Connect
        audioSource.connect(adsrNode)
        adsrNode.connect(sampleLevelNode)
        sampleLevelNode.connect(mixerLevelNode)
        mixerLevelNode.connect(mixerPanNode)
        mixerPanNode.connect(window.audiocontext.destination)

        // Play sample
        audioSource.start(0, 0)

        // Show which sample in pad has been played
        instance.trigger('sample-played', { padIdx, sampleNr: s })
      }
    }
  }

  instance.init = () => {}

  instance.reset = () => {}

  return instance
}

export {
  factory as samplerFactory
}
