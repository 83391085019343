import { completeAssign } from 'js/utils/complete-assign'
import { merge } from 'js/utils/merge'
import { bindEvent, v } from 'js/utils/v'

// Defaults for view model
const defaults = {}

/**
 * @function template
 *
 * @desc Renders template with given view model.
 *
 * @param {Object} data - View model
 * @returns {string} HTML string of component
 */
const template = (data) => `
<div class="bank-select">
    <ul class="bank-select__banks">
        <li class="bank-select__bank-item">
            <div class="bank-select__bank">
                <label class="button">
                    <span class="button__label">A</span>
                    <input class="bank-select__btn button__button bank-a" type="radio" value="0" name="bank-select" data-ref="bankSelect">
                </label>
            </div>
        </li>
        
        <li class="bank-select__bank-item">
            <div class="bank-select__bank">
                <label class="button">
                    <span class="button__label">B</span>
                    <input class="bank-select__btn button__button bank-b" type="radio" value="1" name="bank-select" data-ref="bankSelect">
                </label>
            </div>
        </li>
        
        <li class="bank-select__bank-item">
            <div class="bank-select__bank">
                <label class="button">
                    <span class="button__label">C</span>
                    <input class="bank-select__btn button__button bank-c" type="radio" value="2" name="bank-select" data-ref="bankSelect">
                </label>
            </div>
        </li>
        
        <li class="bank-select__bank-item">
            <div class="bank-select__bank">
                <label class="button">
                    <span class="button__label">D</span>
                    <input class="bank-select__btn button__button bank-d" type="radio" value="3" name="bank-select" data-ref="bankSelect">
                </label>
            </div>
        </li>
    </ul>
</div>
`

/**
 * View factory
 *
 * @param {string|Element} el - Selector or DOM element. This element will be replaced by the rendered component.
 * @param {Object} viewModel - View model
 * @param {Element|Document} [root] - This is only used when `el` is a selector.
 *
 * @returns {Object} View instance
 */
const view = (el, viewModel = {}, root = document) => {
  const vm = merge({}, defaults, viewModel)
  const instance = completeAssign({}, v({
    tpl: view.tpl,
    data: vm,
    mount: el,
    root
  }))

  // Private vars

  // Private methods
  const init = () => {
    bind()
  }

  const bind = () => {
    bindEvent('change', instance.refs.bankSelect, (e) => {
      instance.trigger('set', { bankNr: Number(e.target.value) })
    })
  }

  // Public vars

  // Public methods
  instance.init = () => {
    instance.refs.bankSelect.forEach((bs) => {
      bs.disabled = false
    })

    instance.refs.bankSelect[0].click()
  }

  instance.reset = () => {
    instance.refs.bankSelect.forEach((bs) => {
      bs.disabled = true
      bs.checked = false
    })
  }

  // Hooks
  instance.on('rendered', init)

  return instance
}

// Static methods
view.tpl = template

export {
  view as bankSelectView
}
