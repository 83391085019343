import { pubsub } from 'js/modules/pubsub'

/**
 * Controller
 *
 * @param {Object} view - View instance
 * @param {Object} model - Model
 */
const controller = ({ view, model }) => {
  // Bindings
  model.on('update', (padConfig) => {
    view.update(padConfig)
  })

  model.on('reset-sample-select', () => {
    view.resetSampleSelect()
  })

  view.on('save-pad-params', ({ padParams }) => {
    pubsub.trigger('pad-edit/update-pad-params', {
      padIdx: model.padIdx,
      padParams
    })
  })

  view.on('save-midi-params', ({ midiParams }) => {
    pubsub.trigger('pad-edit/update-midi-params', {
      padIdx: model.padIdx,
      midiParams
    })
  })

  pubsub.on('app/reset', () => {
    model.reset()
    view.reset()
  })

  pubsub.on('program-manager/pgm-update', () => {
    model.getPadConfig()
  })

  pubsub.on('pad/select', ({ padIdx }) => {
    model.getPadConfig(padIdx)
  })

  pubsub.on('sampler/pad-select', ({ padIdx }) => {
    model.getPadConfig(padIdx)
  })

  // Render view
  view.render()
}

export {
  controller as padEditController
}
