import { pubsub } from '../../js/modules/pubsub'

/**
 * Controller
 *
 * @param {Object} view - View instance
 * @param {Object} model - Model
 */
const controller = ({ model }) => {
  // Bindings
  model.on('sample-played', ({ padIdx, sampleNr }) => {
    pubsub.trigger('sampler/sample-played', { padIdx, sampleNr })
  })

  pubsub.on('app/reset', () => {
    model.reset()
  })

  pubsub.on('app/init', () => {
    model.init()
  })

  pubsub.on('pad/play', ({ padIdx }) => {
    model.playPad(padIdx)
  })

  pubsub.on('pad/stop', ({ padIdx }) => {
    model.stopPad(padIdx)
  })

  pubsub.on('midi/noteOn', ({ note, velocity }) => {
    const padIdx = model.getPadIdx(note)

    if (padIdx > -1) {
      model.playPad(padIdx, velocity)

      pubsub.trigger('sampler/pad-select', {
        padIdx
      })

      pubsub.trigger('sampler/pad-noteOn', {
        padIdx
      })
    }
  })

  pubsub.on('midi/noteOff', ({ note }) => {
    const padIdx = model.getPadIdx(note)

    if (padIdx > -1) {
      model.stopPad(padIdx)

      pubsub.trigger('sampler/pad-noteOff', {
        padIdx
      })
    }
  })
}

export {
  controller as samplerController
}
