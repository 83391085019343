import { pubsub } from 'js/modules/pubsub'

/**
 * Controller
 *
 * @param {Object} view - View instance
 * @param {Object} model - Model
 */
const controller = ({ view }) => {
  // view.model = model

  // Bindings
  view.on('save', ({ sampleNr, padIdx, sampleParams, globalEdit }) => {
    pubsub.trigger('sample-edit/update-sample-params', {
      sampleNr,
      padIdx,
      sampleParams,
      globalEdit
    })
  })

  pubsub.on('library/update', () => {
    view.updateSampleSelect()
  })

  pubsub.on('pad/select', ({ padIdx }) => {
    view.setCurrentPadIdx(padIdx)
  })

  pubsub.on('sampler/pad-select', ({ padIdx }) => {
    view.setCurrentPadIdx(padIdx)
  })

  // Render view
  view.render(false)
}

export {
  controller as sampleEditController
}
