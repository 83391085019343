// import { merge } from 'js/utils/merge'
import { parseProgram, writeProgramToBuffer } from 'js/modules/program'
import { writeFile } from 'js/utils/fs'
import { actions } from 'js/modules/actions'
import { observer } from 'js/utils/observer'
import { copyObject } from '../../js/utils/copy-object'

// const defaults = {
// }

const config = {
  emptyPgmName: '--'
}

const factory = () => {
  const instance = {}
  // const settings = merge({}, defaults, options)

  Object.assign(instance, observer())

  // Private vars
  let pgm
  let pgmName

  // Private methods
  const readDirectory = async (pgmDirectoryHandle) => {
    let pgmFileHandle
    let programFileName
    const wavHandles = []

    // 1. Get all file handles
    for await (const entry of pgmDirectoryHandle.values()) {
      // Get only the first found pgm file.
      if (entry.kind === 'file' && /.*\.PGM/i.test(entry.name) && pgmFileHandle === undefined) {
        pgmFileHandle = await pgmDirectoryHandle.getFileHandle(entry.name, { create: false })
        programFileName = entry.name.replace(/.PGM$/i, '')
      }

      // Get all wave handles
      if (entry.kind === 'file' && /.*\.WAV/i.test(entry.name)) {
        wavHandles.push(await pgmDirectoryHandle.getFileHandle(entry.name, { create: false }))
      }
    }

    const wavFiles = []
    for (let i = wavHandles.length; i--;) {
      wavFiles.push(await wavHandles[i].getFile())
    }

    let pgmFile = await pgmFileHandle.getFile()
    pgmFile = await pgmFile.arrayBuffer()

    return {
      programFileName,
      pgmConfig: parseProgram(pgmFile),
      sampleFiles: wavFiles
    }
  }

  // Public vars

  // Public methods
  /**
   * Save PGM with samples to a directory on the filesystem.
   * Each PGM with its samples should be saved in its own folder.
   *
   * @param pgmName
   * @param {boolean} [withSamples=true]
   */
  instance.saveProgram = async (pgmName, withSamples = true) => {
    const pgmBuffer = writeProgramToBuffer(pgm)

    // 1. Choose directory where program directory will be created.
    const destinationDirectory = await window.showDirectoryPicker()

    // 2. Get program directory. If it doesn't exist it will be created.
    const pgmDirectory = await destinationDirectory.getDirectoryHandle(pgmName, { create: true })

    // 3. Write PGM to program directory
    await writeFile(`${pgmName}.PGM`, pgmBuffer, pgmDirectory)

    if (withSamples) {
      // Save all used samples in the same program directory
      const usedSamples = actions.getReferencedSamples()

      for (let i = usedSamples.length; i--;) {
        await writeFile(usedSamples[i].fileName, usedSamples[i].arrayBuffer, pgmDirectory)
      }
    }
  }

  instance.updateSampleParams = (sampleNr, padIdx, sampleParams, globalEdit) => {
    sampleParams.sampleName = actions.getSampleName(sampleParams.sampleId)
    delete sampleParams.sampleId

    Object.assign(pgm.pads[padIdx].samples[sampleNr], sampleParams)

    if (globalEdit) {
      const sampleParamsCopy = copyObject(sampleParams)
      delete sampleParamsCopy.sampleName

      for (let i = 64; i--;) {
        if (i === padIdx) {
          continue
        }

        Object.assign(pgm.pads[i].samples[sampleNr], sampleParamsCopy)
      }
    }

    instance.trigger('pgm-update')
  }

  instance.updatePadParams = (padIdx, padParams) => {
    Object.assign(pgm.pads[padIdx], padParams)
  }

  instance.updateMidiParams = (padIdx, midiParams) => {
    pgm.midi.padToMidi[padIdx] = midiParams.padToMidi

    instance.trigger('pgm-update')
  }

  instance.showPicker = async () => {
    let pgmDirectoryHandle

    try {
      pgmDirectoryHandle = await window.showDirectoryPicker()
    } catch (e) {
      // Action has been aborted.
      return
    }

    const { programFileName, pgmConfig, sampleFiles } = await readDirectory(pgmDirectoryHandle)

    actions.load(programFileName, pgmConfig, sampleFiles)
  }

  /**
   * Load pgm to this instance.
   * Samples will be loaded to library
   *
   * @param {string} programFileName - PGM name
   * @param {Object} pgmConfig
   * @param {Array} sampleFiles - Array fo File objects
   */
  instance.load = async (programFileName, pgmConfig, sampleFiles) => {
    pgmName = programFileName
    pgm = pgmConfig
    await actions.loadFilesToLibrary(sampleFiles)
  }

  instance.reset = () => {
    pgmName = config.emptyPgmName

    instance.trigger('update', {
      pgmName
    })
  }

  instance.init = () => {
    instance.trigger('update', {
      pgmName
    })
  }

  instance.getProgram = () => copyObject(pgm)

  // Initialisation

  return instance
}

export {
  factory as programManagerFactory
}
