import { BANKS } from 'js/modules/program'
import { actions } from 'js/modules/actions'
import { observer } from 'js/utils/observer'

const factory = () => {
  const instance = {}

  Object.assign(instance, observer())

  // Private vars
  let currentPadIdx = -1
  let currentPadConfig = {}

  // Private methods

  // Public vars
  Object.defineProperty(instance, 'padIdx', {
    get: () => currentPadIdx
  })

  // Public methods
  instance.getPadConfig = (padIdx) => {
    if (padIdx === undefined) {
      padIdx = currentPadIdx
    }

    const bankNr = Math.floor(padIdx / 16)
    const padData = actions.getProgramConfig().pads[padIdx]
    const midiNote = actions.getProgramConfig().midi.padToMidi[padIdx]

    currentPadConfig = padData
    currentPadConfig.title = `PAD: ${BANKS[bankNr]}${((padIdx % 16) + 1).toString().padStart(2, '0')}`
    currentPadConfig.samples = padData.samples.map((s) => {
      s.sampleId = actions.getSampleId(s.sampleName)
      return s
    })
    currentPadConfig.midiNote = midiNote

    instance.trigger('update', currentPadConfig)

    if (currentPadIdx !== padIdx) {
      currentPadIdx = padIdx
      instance.trigger('reset-sample-select')
    }
  }

  instance.reset = () => {
    currentPadIdx = -1
    currentPadConfig = {}
  }

  // Initialisation

  return instance
}

export {
  factory as padEditFactory
}
