import { pubsub } from 'js/modules/pubsub'

/**
 * Controller
 *
 * @param {Object} view - View instance
 * @param {Object} model - Model
 */
const controller = ({ view, model }) => {
  // Bindings
  model.on('update', (padConfig) => {
    view.update(padConfig)
  })

  view.on('click', () => {
    pubsub.trigger('pad/play', {
      padIdx: model.padIdx
    })

    pubsub.trigger('pad/select', {
      padIdx: model.padIdx
    })
  })

  view.on('pointer-up', () => {
    pubsub.trigger('pad/stop', {
      padIdx: model.padIdx
    })
  })

  view.on('drop-sample', ({ sampleParams }) => {
    pubsub.trigger('pad/drop-sample', ({
      padIdx: model.padIdx,
      sampleNr: 0, // Always assign dropped sample to first samples slot of pad.
      sampleParams
    }))
  })

  pubsub.on('sampler/sample-played', ({ padIdx, sampleNr }) => {
    if (padIdx === model.padIdx) {
      view.highlightSample(sampleNr)
    }
  })

  pubsub.on('sampler/pad-noteOn', ({ padIdx }) => {
    if (padIdx === model.padIdx) {
      view.noteOn()
    }
  })

  pubsub.on('sampler/pad-noteOff', ({ padIdx }) => {
    if (padIdx === model.padIdx) {
      view.noteOff()
    }
  })

  pubsub.on('program-manager/pgm-update', () => {
    model.setPadData()
  })

  pubsub.on('app/init', () => {
    model.setPadData()
  })

  // Render view
  view.render()
}

export {
  controller as padController
}
