import { pubsub } from 'js/modules/pubsub'

/**
 * Controller
 *
 * @param {Object} view - View instance
 */
const controller = ({ view }) => {
  // Bindings
  view.on('set', ({ bankNr }) => {
    pubsub.trigger('bank-select/set', { bankNr })
  })

  pubsub.on('app/reset', () => {
    view.reset()
  })

  pubsub.on('app/init', () => {
    view.init()
  })

  // Render view
  view.render()
}

export {
  controller as bankSelectController
}
