import { merge } from 'js/utils/merge'
import { actions } from 'js/modules/actions'
import { observer } from 'js/utils/observer'
import { midiNotes } from 'js/utils/midi-notes'

const defaults = {}

// const config = {}

const factory = (options = {}) => {
  const instance = {}
  const settings = merge({}, defaults, options)

  Object.assign(instance, observer())

  // Private vars

  // Private methods

  // Public vars
  Object.defineProperty(instance, 'padIdx', {
    get: () => settings.padIdx
  })

  // Public methods
  instance.setPadData = () => {
    const padConfig = actions.getProgramConfig().pads[settings.padIdx]
    const midi = actions.getProgramConfig().midi.padToMidi

    const note = `${midiNotes[midi[settings.padIdx]]} (${midi[settings.padIdx]})`

    instance.trigger('update', {
      sampleNames: padConfig.samples.map((s) => s.sampleName),
      sampleLevels: padConfig.samples.map((s) => s.level),
      note: note
    })
  }

  // Initialisation

  return instance
}

export {
  factory as padFactory
}
