import { placeholder } from 'js/utils/v'
import { sectionTitleView } from 'components/section-title/section-title.view'

/**
 * @function template
 *
 * @desc Renders template with given view model.
 *
 * @param {Object} data - View model
 * @returns {string} HTML string of component
 */
const template = (data) => `
<div class="dmpc">
    <div class="dmpc__logo">
        MPC1000<br>Program Editor
    </div>
    
    <div class="dmpc__program">
        ${sectionTitleView.tpl({ title: 'program' })}
        ${placeholder('programManager', { ref: 'programManager' })}
    </div>
    
    <div class="dmpc__midi-monitor">
        ${placeholder('midiMonitor', { ref: 'midiMonitor' })}
    </div>

    <div class="dmpc__sample-library">
        ${sectionTitleView.tpl({ title: 'Samples' })}
        ${placeholder('sampleLibrary', { ref: 'sampleLibrary' })}
    </div>

    <div class="dmpc__bank-select">
        ${sectionTitleView.tpl({ title: 'Pad bank' })}
        ${placeholder('bankSelect', { ref: 'bankSelect' })}
    </div>
    
    <div class="dmpc__pad-grid">
        ${placeholder('padGrid', { ref: 'pads' })}
    </div>

    <div class="dmpc__pad-detail">
        ${sectionTitleView.tpl({ title: 'Pad edit' })}
        <div class="dmpc__pad-edit" data-ref="padEditContainer"></div>
    </div>
</div>
`

const view = {}

view.tpl = template

export {
  view as appFrameView
}
