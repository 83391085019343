import { setComponentsMapping } from 'js/utils/v'
import { bankSelectView } from 'components/bank-select/bank-select.view'
import { padView } from 'components/pad/pad.view'
import { padEditView } from 'components/pad-edit/pad-edit.view'
import { sampleEditSelectView } from 'components/sample-edit-select/sample-edit-select.view'
import { sampleEditView } from 'components/sample-edit/sample-edit.view'
import { programManagerView } from 'components/program-manager/program-manager.view'

const mapping = {
  bankSelect: bankSelectView,
  pad: padView,
  padEdit: padEditView,
  sampleEditSelect: sampleEditSelectView,
  sampleEdit: sampleEditView,
  programManager: programManagerView
}

setComponentsMapping(mapping)
