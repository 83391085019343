import { completeAssign } from 'js/utils/complete-assign'
import { merge } from 'js/utils/merge'
import { createView, v, placeholder } from 'js/utils/v'

import { padController } from 'components/pad/pad.controller'
import { padFactory } from 'components/pad/pad'

// Defaults for view model
const defaults = {}

/**
 * @function template
 *
 * @desc Renders template with given view model.
 *
 * @param {Object} data - View model
 * @returns {string} HTML string of component
 */
const template = (data) => {
  let pads = ''

  for (let b = 0; b < 4; b++) {
    pads += `<div class="pads__bank" data-bank="${b}" data-ref="bank">`
    pads += '<ul class="pads__row">'

    for (let p = 0; p < 16; p++) {
      pads += '<li class="pads__item">'
      pads += placeholder('pad', { data: { padNr: p, padIdx: (b * 16) + p, bankNr: b }, ref: 'pad' })
      pads += '</li>'
    }

    pads += '</ul>'
    pads += '</div>'
  }

  return `
<div class="pads">
    ${pads}
</div>
`
}

/**
 * View factory
 *
 * @param {string|Element} el - Selector or DOM element. This element will be replaced by the rendered component.
 * @param {Object} viewModel - View model
 * @param {Element|*} [root=document] - This is only used when `el` is a selector.
 *
 * @returns {Object} View instance
 */
const view = (el, viewModel = {}, root = document) => {
  const vm = merge({}, defaults, viewModel)
  const instance = completeAssign({}, v({
    tpl: view.tpl,
    data: vm,
    mount: el,
    root
  }))

  // Private vars

  // Private methods
  const init = () => {
    for (let p = 0; p < 64; p++) {
      padController({
        view: createView(instance.refs.pad[p]),
        model: padFactory({ padIdx: p })
      })
    }

    bind()
  }

  const bind = () => {}

  // Public vars

  // Public methods
  instance.setActiveBank = (bankNr) => {
    instance.refs.bank.forEach((b, idx) => {
      if (bankNr === idx) {
        b.setAttribute('data-state', 'is-active')
      } else {
        b.setAttribute('data-state', '')
      }
    })
  }

  // Hooks
  instance.on('rendered', init)

  return instance
}

// Static methods
view.tpl = template

export {
  view as padsView
}
