import { pubsub } from 'js/modules/pubsub'
import { actions } from '../../js/modules/actions'

/**
 * Controller
 *
 * @param {Object} view - View instance
 * @param {Object} model - Parent view instance.
 */
const controller = ({ view, model }) => {
  // Bindings
  view.on('load', () => {
    model.showPicker().then(() => {
      pubsub.trigger('app/init')
    })
  })

  view.on('save', ({ pgmName }) => {
    model.saveProgram(pgmName).then()
  })

  view.on('reset', () => {
    actions.reset()
  })

  model.on('update', ({ pgmName }) => {
    view.update(pgmName)
  })

  model.on('pgm-update', () => {
    pubsub.trigger('program-manager/pgm-update')
  })

  pubsub.on('app/reset', () => {
    model.reset()
    view.reset()
  })

  pubsub.on('app/init', () => {
    model.init()
    view.init()
  })

  pubsub.on('pad/drop-sample', ({ sampleNr, padIdx, sampleParams }) => {
    model.updateSampleParams(sampleNr, padIdx, sampleParams)
  })

  pubsub.on('sample-edit/update-sample-params', ({ sampleNr, padIdx, sampleParams, globalEdit }) => {
    model.updateSampleParams(sampleNr, padIdx, sampleParams, globalEdit)
  })

  pubsub.on('pad-edit/update-pad-params', ({ padIdx, padParams }) => {
    model.updatePadParams(padIdx, padParams)
  })

  pubsub.on('pad-edit/update-midi-params', ({ padIdx, midiParams }) => {
    model.updateMidiParams(padIdx, midiParams)
  })

  // Render view
  view.render()
}

export {
  controller as programManagerController
}
