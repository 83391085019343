/**
 * @function template
 *
 * @desc Renders template with given view model.
 *
 * @param {Object} data - View model
 * @returns {string} HTML string of component
 */
const template = ({ sampleId, filename, samplename, size, duration }) => `
<tr class="sample-row" draggable="true">
    <td>
        <div class="sample-row__samplename" title="Filename: ${filename}">
            <form>
                <input class="sample-row__samplename-input" 
                    type="text" 
                    value="${samplename}" 
                    data-ref="samplenameInput"
                    data-sample-id="${sampleId}"
                    readonly/>
            </form>    
        </div>
    </td>
    <td><div class="sample-row__size tx-mono-small">${size}</div></td>
    <td><div class="sample-row__size tx-mono-small">${duration}</div></td>
    <td>
        <div class="sample-row__buttons">
            <button class="sample-row__btn play" data-ref="playSample" data-sample-id="${sampleId}">
                <span class="visuallyhidden">play sample</span>
            </button>
            
            <button class="sample-row__btn delete" data-ref="deleteSample" data-sample-id="${sampleId}">
                <span class="visuallyhidden">delete sample</span>
            </button>
        </div>
    </td>
</tr>
`

const view = {}

view.tpl = template

export {
  view as sampleRowView
}
